import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { BureauComponent } from './bureau.component';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [BureauComponent],
})
export class BureauModule {}
