import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { LacertaCmsNgrxFacade, LacertaDynamicPageWithMetaComponent } from '@lacerta/cms';
import { BureauPage } from '../../core/willem-wagtail/willem-wagtail.model';

@Component({
  templateUrl: './bureau.component.html',
  styleUrls: ['./bureau.component.scss'],
})
export class BureauComponent extends LacertaDynamicPageWithMetaComponent<BureauPage> implements OnInit, OnDestroy {
  constructor(lacertaCmsNgrxFacade: LacertaCmsNgrxFacade, private renderer: Renderer2) {
    super(lacertaCmsNgrxFacade);
  }

  ngOnInit() {
    this.renderer.addClass(document.body, 'bureau');
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'bureau');
  }
}
