<header>
  <h1>Projecten</h1>
</header>

<section class="categories">
  <form [formGroup]="categoriesFormGroup">
    <label *ngFor="let item of categoriesFormGroup.controls | keyvalue">
      <willem-checkbox [formControlName]="item.key" (change)="isAllSelected()">
        {{ item.key }}
      </willem-checkbox>
    </label>
  </form>
  <label>
    <willem-checkbox (change)="checkUncheckAll()" [(ngModel)]="allSelected"> alles </willem-checkbox>
  </label>
</section>

<section class="projectsrows">
  <ng-container *ngIf="filteredProjects$ | async as projects">
    <ul>
      <li
        *ngFor="let project of projects"
        [ngClass]="{ selected: project.id === selectedProject, showing_details: selectedProject }"
        [ngStyle]="{
          flexBasis: (project.cover.width * 200) / project.cover.height + 'px',
          flexGrow: (project.cover.width * 200) / project.cover.height
        }"
        [lacertaBackgroundImage]="project.cover | lacertaMapper: mapImageRenditionFieldToUiImage"
        (click)="selectProject(project.id)"
      >
        <div class="title-container">
          <div class="title">
            <span>{{ project.title }} / {{ project.year }}</span>
          </div>
        </div>
        <div class="gallery">
          <ul>
            <ng-container *ngFor="let projectImage of project.images">
              <li
                *ngIf="projectImage.value.image?.original_image"
                [ngStyle]="{
                  flexBasis: (projectImage.value.image.original_image.width * 200) / projectImage.value.image.original_image.height + 'px'
                }"
                (click)="openModal(projectImage.value.image); $event.stopPropagation()"
                [lacertaBackgroundImage]="projectImage.value.image | asRenderedUiImage: 'thumbnail'"
              ></li>
              <li
                *ngIf="projectImage.value.thumbnail && projectImage.value.url"
                [ngStyle]="{
                  flexBasis:
                    (projectImage.value.thumbnail.original_image.width * 200) / projectImage.value.thumbnail.original_image.height + 'px'
                }"
                [lacertaBackgroundImage]="projectImage.value.thumbnail | asRenderedUiImage: 'thumbnail'"
              >
                <a [href]="projectImage.value.url" target="_blank"></a>
              </li>
            </ng-container>
          </ul>
        </div>
      </li>
    </ul>
  </ng-container>
</section>

<div class="modal" *ngIf="modal$ | async as modal" (click)="openModal(undefined); $event.stopPropagation()">
  <img [src]="modal.renditions.large.src" alt="Afbeelding project" />
</div>
