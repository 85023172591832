import { Component, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Project } from '../../core/willem-wagtail/willem-wagtail.model';
import { FormControl, FormGroup } from '@angular/forms';
import { filter, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LacertaCmsNgrxFacade, LacertaImageChooserBlock, mapImageRenditionFieldToUiImage } from '@lacerta/cms';
import { WillemWagtailPageType } from '../../core/willem-wagtail/page/page.model';

@UntilDestroy()
@Component({
  selector: 'willem-work',
  templateUrl: './work.component.html',
  styleUrls: ['./work.component.scss'],
})
export class WorkComponent implements OnInit {
  window = window;
  projects$?: Observable<Project[]>;
  filteredProjects$ = new Subject<Project[]>();
  categoriesFormGroup = new FormGroup({});
  allSelected = true;

  mapImageRenditionFieldToUiImage = mapImageRenditionFieldToUiImage;

  selectedProject: number | undefined;
  modal$ = new Subject<LacertaImageChooserBlock>();

  constructor(private lacertaCmsNgrxFacade: LacertaCmsNgrxFacade) {}

  ngOnInit() {
    this.lacertaCmsNgrxFacade.loadPageByType({ pageType: WillemWagtailPageType.project });
    this.projects$ = this.lacertaCmsNgrxFacade.selectDetailPagesByType(WillemWagtailPageType.project) as Observable<Project[]>;

    this.projects$.pipe(filter((projects) => projects.length > 0)).subscribe((projects) => {
      this.filteredProjects$.next(projects);
      for (const category of this.getCategories(projects)) {
        if (!this.categoriesFormGroup.contains(category)) {
          this.categoriesFormGroup.addControl(category, new FormControl(true));
        }
      }
    });

    this.categoriesFormGroup.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      this.filterProjects(value);
    });
  }

  filterProjects(controls: { [key: string]: unknown }) {
    const enabledCategories = Object.keys(controls).filter((key) => controls[key]);
    this.projects$?.pipe(take(1)).subscribe((projects) => {
      this.filteredProjects$.next(
        projects.filter((item) => {
          for (const category of item.categories) {
            if (!enabledCategories.find((value) => value === category)) {
              return false;
            }
          }
          return true;
        })
      );
    });
  }

  getCategories(projects: Project[]) {
    const categories = new Set<string>();
    for (const project of projects) {
      for (const category of project.categories) {
        categories.add(category);
      }
    }
    return categories;
  }

  selectProject(id: number) {
    if (this.selectedProject === id) {
      this.selectedProject = undefined;
    } else {
      this.selectedProject = id;
    }
  }

  openModal(image: LacertaImageChooserBlock | undefined) {
    this.modal$.next(image);
  }

  isAllSelected() {
    this.allSelected = Object.keys(this.categoriesFormGroup.controls).every((key) => this.categoriesFormGroup.controls[key].value);
  }

  checkUncheckAll() {
    Object.keys(this.categoriesFormGroup.controls).forEach((key) => {
      this.categoriesFormGroup.controls[key].setValue(true);
    });
  }
}
