import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { WorkComponent } from './work.component';
import { InViewportModule } from 'ng-in-viewport';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LacertaImageModule } from '@lacerta/ui';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    BrowserAnimationsModule,
    InViewportModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    LacertaImageModule,
  ],
  declarations: [WorkComponent],
  exports: [WorkComponent],
})
export class WorkModule {}
