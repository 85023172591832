<willem-header [menuPages]="menu$ | async"></willem-header>
<router-outlet></router-outlet>
<footer>
  <section class="contact" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-evenly stretch" fxLayoutAlign.lt-sm="center none">
    <div class="details">
      <h3>DE CRAENE Willem Architect</h3>
      <div class="adres">
        <p>Dombos 6</p>
        <p>9790 Ooike</p>
      </div>
      <div class="tel"><a href="tel:+32478363742">0478 363 742</a></div>
      <div class="mail"><a href="mailto:willem.de.craene@telenet.be">willem.de.craene@telenet.be</a></div>
    </div>
    <div class="openingsuren">
      <h3>&nbsp;</h3>
      <ul>
        <li>elke werkdag van 7u00 tot 20u00</li>
        <li>en zaterdag van 8u00 tot 12u00</li>
      </ul>
    </div>
  </section>
</footer>
