import { Component } from '@angular/core';
import { LacertaCmsNgrxFacade } from '@lacerta/cms';

@Component({
  selector: 'willem-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class WillemAppComponent {
  menu$ = this.lacertaCmsNgrxFacade.menu$;

  constructor(private lacertaCmsNgrxFacade: LacertaCmsNgrxFacade) {}
}
