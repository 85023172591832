import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { WagtailMenuPage } from '@lacerta/wagtail';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

const defaultScrollDivider = 300;

const shadowDivider = 4;

@Component({
  selector: 'willem-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewChecked {
  constructor(private sanitization: DomSanitizer, private cdRef: ChangeDetectorRef) {}
  @Input()
  menuPages?: WagtailMenuPage[] | null;
  @Input()
  currentSlug?: string;

  defaultTitleMargin = 0;
  defaultMenuMargin = 0;

  titleMargin = this.defaultTitleMargin;
  menuMargin = this.defaultMenuMargin;
  menuHeight = 0;
  fixed = false;
  shadow?: SafeStyle;
  opacity = '0';
  inverseOpacity = '1';

  menuOpen = false;

  @ViewChild('header', { read: ElementRef, static: true }) header?: ElementRef;

  @ViewChild('menu', { read: ElementRef, static: true }) menu?: ElementRef;

  @ViewChild('title', { read: ElementRef, static: true }) title?: ElementRef;

  private readonly maxBoundingClientRectBottom = 0;

  ngOnInit(): void {
    this.shadow = this.sanitization.bypassSecurityTrustStyle('0px 5px 20px 0px rgba(191,191,191,0)');
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.fixed = this.title?.nativeElement.getBoundingClientRect().bottom <= this.maxBoundingClientRectBottom;
    this.shadow = this.sanitization.bypassSecurityTrustStyle(
      `0px 5px 20px 0px rgba(191,191,191,${Number.parseFloat(
        `${Math.min(1, window.scrollY / defaultScrollDivider) / shadowDivider}`
      ).toFixed(2)})`
    );
    this.opacity = Number.parseFloat(`${Math.min(1, window.scrollY / defaultScrollDivider)}`).toFixed(2);
    this.inverseOpacity = Math.max(0, 1 - Number.parseFloat(this.opacity)).toFixed(2);
  }

  ngAfterViewChecked(): void {
    if (this.menuPages) {
      if (this.menuHeight <= 0) {
        this.menuHeight = this.menu?.nativeElement.offsetHeight;
        this.cdRef.detectChanges();
      }
    }
  }

  closeHamburger() {
    this.menuOpen = false;
  }
}
