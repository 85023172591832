import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LacertaCmsModule } from '@lacerta/cms';
import { LacertaMapperModule } from '@lacerta/util';
import { CheckboxModule } from './checkbox/checkbox.module';

@NgModule({
  imports: [CommonModule, RouterModule, FlexLayoutModule, LacertaCmsModule, LacertaMapperModule, CheckboxModule],
  exports: [CommonModule, RouterModule, FlexLayoutModule, LacertaCmsModule, LacertaMapperModule, CheckboxModule],
})
export class SharedModule {}
