<header #header>
  <div #title class="container">
    <div fxLayout="row" fxLayoutAlign="space-around center">
      <div class="inner-container" fxFlex>
        <hgroup [ngStyle]="{ opacity: inverseOpacity }">
          <h1>DE CRAENE Willem</h1>
        </hgroup>
        <div [inlineSVG]="'../../../assets/huisje.svg'"></div>
      </div>
      <lacerta-hamburger [fxHide.gt-sm]="true" [(menuOpen)]="menuOpen"></lacerta-hamburger>
    </div>
  </div>
  <div class="menu" #menu [ngClass]="{ fixed: fixed }">
    <nav
      [fxShow.lt-md]="menuOpen"
      fxLayout="row"
      fxLayoutAlign="center stretch"
      fxLayoutGap="0"
      fxLayout.lt-md="column"
      fxLayoutGap.lt-md="0"
    >
      <h1 [ngStyle]="{ opacity: opacity }">DE CRAENE Willem</h1>
      <ng-container *ngFor="let item of menuPages">
        <a
          *ngIf="!item.children || item.children.length === 0"
          [routerLink]="item.page.meta.slug | slugRoute"
          (click)="closeHamburger()"
          routerLinkActive="is-active"
          ngClass.lt-md="menu-item-responsive"
          class="item"
        >
          {{ item.page.title }}
        </a>
        <div class="item" *ngIf="item.children && item.children.length > 0">
          + {{ item.page.title }}
          <ul class="submenu">
            <li *ngFor="let subitem of item.children">
              <a
                [routerLink]="subitem.page.meta.slug | slugRoute"
                (click)="closeHamburger()"
                routerLinkActive="is-active"
                ngClass.lt-md="menu-item-responsive"
                class="subitem"
              >
                {{ subitem.page.title }}
              </a>
            </li>
          </ul>
        </div>
      </ng-container>
    </nav>
  </div>
</header>
