import { NgModule } from '@angular/core';
import { HeaderComponent } from './header.component';
import { SharedModule } from '../../shared/shared.module';
import { InlineSVGModule } from 'ng-inline-svg';
import { FormsModule } from '@angular/forms';
import { LacertaRouterModule } from '@lacerta/cms';
import { HamburgerModule } from '@lacerta/ui';

@NgModule({
  declarations: [HeaderComponent],
  imports: [SharedModule, InlineSVGModule, FormsModule, LacertaRouterModule, HamburgerModule],
  exports: [HeaderComponent],
})
export class HeaderModule {}
