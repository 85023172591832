import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { LacertaCmsNgrxFacade, LacertaDynamicPageWithMetaComponent, LacertaWagtailPage } from '@lacerta/cms';

@Component({
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent extends LacertaDynamicPageWithMetaComponent<LacertaWagtailPage> implements OnInit, OnDestroy {
  showPopup = false;

  constructor(lacertaCmsNgrxFacade: LacertaCmsNgrxFacade, private renderer: Renderer2) {
    super(lacertaCmsNgrxFacade);
  }

  ngOnInit() {
    this.renderer.addClass(document.body, 'contact');
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'contact');
  }
}
