import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { WillemAppComponent } from './app.component';
import { WillemCoreModule } from './core/core.module';
import { InlineSVGModule } from 'ng-inline-svg';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HeaderModule } from './features/header/header.module';
import { FormsModule } from '@angular/forms';
import { InViewportModule } from 'ng-in-viewport';

@NgModule({
  declarations: [WillemAppComponent],
  imports: [BrowserModule, WillemCoreModule, FlexLayoutModule, InlineSVGModule.forRoot(), HeaderModule, FormsModule, InViewportModule],
  bootstrap: [WillemAppComponent],
})
export class AppModule {}
