<div class="banner">
  <h2>
    Architectuur<br />
    <span class="small">&amp; Interieurvormgeving</span>
  </h2>
  <div class="svg">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 118 41" id="svg823" width="118mm" height="41mm">
      <defs id="defs2" />
      <g id="layer2" style="display: inline; opacity: 0.3">
        <path
          style="
            display: inline;
            fill: none;
            stroke: #96c864;
            stroke-width: 0.15;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-dasharray: 0.5, 0.5;
            stroke-dashoffset: 0;
          "
          d="M 157.98218,31.292753 H -25.428862"
          id="path982"
        />
        <path
          style="
            display: inline;
            fill: none;
            stroke: #96c864;
            stroke-width: 0.15;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-dasharray: 0.5, 0.5;
            stroke-dashoffset: 0;
          "
          d="M 157.98218,31.292753 32.622906,16.478393 -25.428862,31.292753 80.280021,25.377193 Z"
          id="path986"
        />
        <path
          style="
            display: inline;
            fill: none;
            stroke: #96c864;
            stroke-width: 0.15;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-dasharray: 0.5, 0.5;
            stroke-dashoffset: 0;
          "
          d="m -25.428862,31.292753 58.050903,9.38354 125.360139,-9.38354 -77.698989,7.03149 z"
          id="path1028"
        />
      </g>
      <g id="layer3" #path_group class="stroke">
        <path
          style="fill: none; stroke: #96c864; stroke-width: 0.25; stroke-linecap: butt; stroke-linejoin: round; stroke-opacity: 1"
          d="m 32.622041,40.676293 7.94e-4,-24.1979 38.710426,4.57021 v 16.76343 z"
          id="path1034"
        />
        <path
          style="fill: none; stroke: #96c864; stroke-width: 0.25; stroke-linecap: butt; stroke-linejoin: round; stroke-opacity: 1"
          d="M 32.622041,40.676293 17.242923,38.194413 V 20.408383 L 23.792686,0.55218217 32.622835,16.478393 Z"
          id="path1036"
        />
        <path
          style="fill: none; stroke: #96c864; stroke-width: 0.25; stroke-linecap: butt; stroke-linejoin: round; stroke-opacity: 1"
          d="M 23.792686,0.55218217 60.822731,8.9011226 71.333261,21.048603 32.622835,16.478393 Z"
          id="path1038"
        />
        <path
          style="fill: none; stroke: #96c864; stroke-width: 0.25; stroke-linecap: butt; stroke-linejoin: round; stroke-opacity: 1"
          d="m 71.333261,37.812033 8.94993,0.51221 -0.003,-12.94705 -7.84684,-8.99291 -2.05856,3.48223"
          id="path1040"
        />
        <path
          style="fill: none; stroke: #96c864; stroke-width: 0.25; stroke-linecap: butt; stroke-linejoin: round; stroke-opacity: 1"
          d="m 80.283191,38.324243 19.855749,-1.78846 v -9.63941 l -19.858919,-1.51918 z"
          id="path1042"
        />
        <path
          style="fill: none; stroke: #96c864; stroke-width: 0.25; stroke-linecap: butt; stroke-linejoin: round; stroke-opacity: 1"
          d="m 100.13894,26.896373 -6.903549,-7.03596 -20.80204,-3.47613"
          id="path1044"
        />
      </g>
    </svg>
    <div class="zinneke">
      <p>
        Architectuur is <span class="green">Dichten</span><br />
        <span class="insprong"></span>in <span class="green">Vormen</span> &amp; <span class="green">Zichten</span>
      </p>
    </div>
  </div>
</div>
<willem-work #projecten></willem-work>
