import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { LacertaCmsNgrxFacade, LacertaDynamicPageWithMetaComponent, LacertaWagtailPage } from '@lacerta/cms';
import { Router } from '@angular/router';

const magicNumber = 100;
const scrollBackBy = -300;
const mSecInSec = 2000;
const nrStepsInPath = 100;
const drawNextTimeoutPerSec = 4;

@Component({
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class WillemHomeComponent extends LacertaDynamicPageWithMetaComponent<LacertaWagtailPage> implements AfterViewInit, OnDestroy {
  constructor(lacertaCmsNgrxFacade: LacertaCmsNgrxFacade, private router: Router) {
    super(lacertaCmsNgrxFacade);
  }
  paths?: SVGGeometryElement[];
  nextPathIndex = 0;

  timeOutIDs: number[] = [];

  @ViewChild('path_group', { read: ElementRef, static: true }) pathGroup?: ElementRef;

  @ViewChild('projecten', { read: ElementRef, static: true }) projecten?: ElementRef;

  ngAfterViewInit() {
    this.paths = this.pathGroup?.nativeElement.getElementsByTagName('path');
    this.drawNext();
    if (this.router.url.indexOf('/projecten') >= 0 && this.projecten) {
      setTimeout(() => {
        if (this.projecten) {
          this.projecten.nativeElement.scrollIntoView(true);
          window.scrollBy(0, scrollBackBy);
        }
      }, magicNumber);
    }
  }

  ngOnDestroy() {
    this.timeOutIDs.forEach(clearTimeout);
  }

  drawNext() {
    if (this.nextPathIndex < this.nrPaths) {
      const path = this.paths?.[this.nextPathIndex];
      if (path) {
        const length = Math.round(path.getTotalLength());
        const duration = length / nrStepsInPath;
        path.style.strokeDasharray = `${length}`;
        path.style.strokeDashoffset = `${length}`;
        path.style.animation = `dash ${duration}s steps(64) forwards`;
        this.nextPathIndex++;
        if (this.nextPathIndex < this.nrPaths) {
          this.timeOutIDs.push(
            window.setTimeout(() => {
              this.drawNext();
            }, (duration * mSecInSec) / drawNextTimeoutPerSec)
          );
        }
      }
    }
  }

  get nrPaths() {
    return this.paths?.length ?? 0;
  }
}
