import { NgModule, Optional, SkipSelf } from '@angular/core';
import { WillemWagtailModule } from './willem-wagtail/willem-wagtail.module';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LacertaCoreModule } from '@lacerta/core';
import { LacertaWagtailComponentTypeMapping } from '@lacerta/cms';
import { WillemHomeComponent } from '../features/home/home.component';
import { ContactComponent } from '../features/contact/contact.component';
import { BureauComponent } from '../features/bureau/bureau.component';

import { InlineSVGModule } from 'ng-inline-svg';
import { HomeModule } from '../features/home/home.module';
import { WorkModule } from '../features/work/work.module';
import { ContactModule } from '../features/contact/contact.module';
import { BureauModule } from '../features/bureau/bureau.module';
import { WillemWagtailPageType } from './willem-wagtail/page/page.model';
import { environment } from '../../environments/environment';
import { LacertaRoutePaths, preventReimport } from '@lacerta/util';

const dynamicFeatureModules = [HomeModule, WorkModule, ContactModule, BureauModule];

const wagtailComponentTypeMapping: LacertaWagtailComponentTypeMapping = [
  [WillemWagtailPageType.home, WillemHomeComponent],
  [WillemWagtailPageType.contact, ContactComponent],
  [WillemWagtailPageType.bureau, BureauComponent],
];

@NgModule({
  imports: [
    CommonModule,
    dynamicFeatureModules,
    WillemWagtailModule,
    LacertaCoreModule.forRoot({
      wagtailComponentTypeMapping,
      customRoutes: [
        {
          path: 'projecten',
          pathMatch: 'full',
          redirectTo: `projecten/${LacertaRoutePaths.home}`,
        },
      ],
      metaConfig: {},
      rootPageType: WillemWagtailPageType.root,
      menuParentPageType: WillemWagtailPageType.root,
      environment,
    }),
    InlineSVGModule.forRoot(),
  ],
  providers: [],
  exports: [RouterModule],
})
export class WillemCoreModule {
  constructor(@Optional() @SkipSelf() parentModule: WillemCoreModule) {
    preventReimport(parentModule, 'WillemCoreModule');
  }
}
