import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'willem-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],

  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent implements ControlValueAccessor {
  checked = false;
  // eslint-disable-next-line
  onChange: any = () => {};
  // eslint-disable-next-line
  onTouch: any = () => {};

  // eslint-disable-next-line
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // eslint-disable-next-line
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  constructor() {}

  writeValue(checked: boolean) {
    this.checked = checked;
  }

  onModelChange(checked: boolean) {
    this.checked = checked;
    this.onChange(checked);
  }
}
